import { animateDOM, uuid } from 'core'

export interface ITabsConfig {
  labelClass?: string
  labelActiveClass?: string

  contentClass?: string
}

export interface ITabItem {
  name: string
}

export interface ITab extends ITabItem {
  id: string
}

export const [useTabsProvider, useTabs] = createInjectionState((config: ITabsConfig = {}) => {
  /** List of tab items {@link ITab} */
  const list = shallowRef<ITab[]>([])

  const activeTabIndex = ref(0)

  const activeTabId = computed(() => list.value[activeTabIndex.value].id)

  function setTab(index: number) {
    animateDOM(() => {
      activeTabIndex.value = index
    })
  }

  function register(item: ITabItem) {
    const tab: ITab = {
      ...item,
      id: uuid()
    }

    list.value.push(tab)
    triggerRef(list)

    return tab
  }

  return { config, list, activeTabIndex, activeTabId, register, setTab }
})
